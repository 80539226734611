import styled from "styled-components";

import { colors, xMarginMobile } from "variables";

const PageNotFound = () => {
  return (
    <Wrapper>
      <Container>
        <Message>Ta stran ne obstaja.</Message>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 18px 0;
  color: ${colors.textDark};
`;

const Container = styled.div`
  margin: 0 ${xMarginMobile};
  text-align: center;
`;

const Message = styled.div`
  margin: 25px 0;
`;

export default PageNotFound;
