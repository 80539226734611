import { useMediaPredicate } from "react-media-hook";

import { breakpoints } from "variables";

export const useIsMobileMOrHigher = () =>
  useMediaPredicate(`(min-width: ${breakpoints.mobileM / 16}em)`);

export const useIsMobileOrHigher = () =>
  useMediaPredicate(`(min-width: ${breakpoints.mobile / 16}em)`);

export const useIsTabletOrHigher = () =>
  useMediaPredicate(`(min-width: ${breakpoints.tablet / 16}em)`);

export const useIsHamburgerLargeOrHigher = () =>
  useMediaPredicate(`(min-width: ${breakpoints.hamburgerLarge / 16}em)`);

export const useIsLargeOrHigher = () =>
  useMediaPredicate(`(min-width: ${breakpoints.large / 16}em)`);

export const useIsLargestOrHigher = () =>
  useMediaPredicate(`(min-width: ${breakpoints.largest / 16}em)`);
