import { useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Auth } from "aws-amplify";

import ButtonIcon from "components/Shared/Button";
import Loader from "components/Shared/Loader";

import { colors, gapSmall } from "variables";

const Login = ({ setUser, isAuthenticating, setAuthenticating }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    setAuthenticating(true);
    setError("");

    try {
      const {
        attributes,
        signInUserSession: {
          accessToken: {
            payload: { "cognito:groups": groups }
          }
        }
      } = await Auth.signIn(email, password);

      if (groups.includes("Admins")) {
        setUser(attributes);
      } else {
        setError("Nimate administratorskih pravic.");
        await Auth.signOut();
      }
    } catch (error) {
      setError("Prijava ni uspela.");
    }

    setAuthenticating(false);
  };

  return (
    <Wrapper>
      {isAuthenticating ? (
        <Loader />
      ) : (
        <>
          <Form onSubmit={handleSubmit}>
            <Email>
              <div>
                <Label>Email</Label>
              </div>
              <Input
                name="email"
                type="text"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Email>
            <Password>
              <div>
                <Label>Geslo</Label>
              </div>
              <Input
                name="password"
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Password>
            {error && <Error>{error}</Error>}
            <StyledButtonIcon type="submit" disabled={!email || !password}>
              Prijava
            </StyledButtonIcon>
          </Form>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 400px;
  margin: 0 auto;
  padding-top: 120px;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.label`
  color: ${colors.black};
  font-family: "Montserrat-Regular";
`;

const Input = styled.input`
  width: 100%;
  border: 1px solid ${colors.text};
  border-radius: 0.25rem;
  padding: 8px;
  color: ${colors.black};
  font-family: "Montserrat-Regular";
`;

const Email = styled.div`
  width: 100%;
`;

const Password = styled.div`
  width: 100%;
  margin-top: ${gapSmall};
`;

const StyledButtonIcon = styled(ButtonIcon)`
  margin-top: ${gapSmall};
  font-family: "Montserrat-Regular";
`;

const Error = styled.div`
  margin-top: ${gapSmall};
  color: ${colors.error};
  font-family: "Montserrat-Regular";
`;

export default withRouter(Login);
