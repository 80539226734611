export const colors = {
  white: "#FFFFFF",
  black: "#000000",
  background: "#fffff0",
  text: "#8080ff",
  salmon: "#ff7878",
  tautara: "#3c3c3b",
  disabled: "#a9a9a9",
  error: "#ff3c3c"
};

export const paddingSmall = "24px";
export const paddingMedium = "72px";
export const paddingLarge = "100px";
export const paddingExtraSmall = "16px";

export const xMarginMobile = "16px";

export const gapSmallest = "10px";
export const gapExtraSmall = "16px";
export const gapSmall = "24px";
export const gapMedium = "72px";
export const gapLarge = "100px";
export const gapMobile = "16px";

export const contentWidth = "950px";
export const contentWidthNarrow = "500px";
export const contentWidthWide = "1800px";
export const contentWidthAdmin = "950px";
export const navigationHeight = "80px";

export const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopM: "1280px",
  laptopL: "1440px",
  desktopS: "1920px",
  desktop: "2560px"
};

export const device = {
  mobile: `(max-width: ${size.laptop})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktopS: `(min-width: ${size.desktopS})`
};

export const breakpoints = {
  largest: 1440,
  large: 1320,
  desktopL: 1200,
  hamburgerLarge: 1024,
  hamburgerSmall: 1023,
  rightRail: 1100,
  desktop: 992,
  tablet: 768,
  mobile: 576,
  mobileM: 375,
  mobileS: 320
};
