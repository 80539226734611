import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { CarouselProvider, Slider, Slide, DotGroup } from "pure-react-carousel";

import { colors } from "variables";
import { minWidth } from "utils";

import Icon from "components/Shared/Icon";

import "pure-react-carousel/dist/react-carousel.es.css";

const Gallery = ({
  slides,
  interval,
  infinite = false,
  imageHeight,
  onLastSlide = () => {},
  ...props
}) => {
  const [index, setIndex] = useState(0);
  const count = slides.length;
  const refInterval = useRef(null);
  const refCarousel = useRef(null);

  const getCurrentSlide = () =>
    refCarousel.current.carouselStore.getStoreState().currentSlide;

  const setCurrentSlide = (currentSlide) => {
    setIndex(currentSlide);
    refCarousel.current.carouselStore.setStoreState({ currentSlide });
  };

  useEffect(() => {
    if (count > 0 && interval) {
      refInterval.current = setInterval(() => {
        const currentSlide = getCurrentSlide();
        if (!infinite && currentSlide + 1 === count - 1) {
          clearInterval(refInterval.current);
        }
        setCurrentSlide((currentSlide + 1) % count);
      }, [interval]);

      return () => clearInterval(refInterval.current);
    }
  }, [count, interval, infinite]);

  useEffect(() => {
    if (index === count - 1) {
      onLastSlide();
    }
  }, [index, count, onLastSlide]);

  const handlePreviousClick = () => {
    clearInterval(refInterval.current);
    const currentSlide = getCurrentSlide();
    setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : count - 1);
  };

  const handleNextClick = () => {
    clearInterval(refInterval.current);
    const currentSlide = getCurrentSlide();
    setCurrentSlide((currentSlide + 1) % count);
  };

  const handleDotClick = (currentSlide) => {
    clearInterval(refInterval.current);
    setCurrentSlide(currentSlide);
  };

  if (count === 0) {
    return null;
  }

  return (
    <Carousel
      ref={refCarousel}
      totalSlides={count}
      dragEnabled={false}
      touchEnabled={false}
      {...props}
    >
      <Slider tabIndex={-1}>
        {slides.map((slide, idx) => (
          <Slide key={slide.alt} tabIndex={-1} index={idx}>
            <ImageWrapper>
              <Image
                src={slide.image}
                alt={slide.alt}
                title={slide.alt}
                imageHeight={imageHeight}
              />
            </ImageWrapper>
            {slide.metadata && <Metadata>{slide.metadata}</Metadata>}
          </Slide>
        ))}
      </Slider>
      <ArrowLeft
        imageHeight={imageHeight}
        onClick={handlePreviousClick}
        disabled={!infinite && index === 0}
      />
      <ArrowRight
        imageHeight={imageHeight}
        onClick={handleNextClick}
        disabled={!infinite && index === count - 1}
      />
      <DotWrapper imageheight={imageHeight}>
        {slides.map((slide, i) => (
          <Dot
            key={slide.alt}
            slide={i}
            isSelected={i === index}
            onClick={() => handleDotClick(i)}
          />
        ))}
      </DotWrapper>
    </Carousel>
  );
};

const Carousel = styled(CarouselProvider)`
  position: relative;
  width: 100%;

  .carousel__slide {
    height: auto;
    padding-bottom: 0 !important;
  }

  .carousel__slide-focus-ring {
    display: none !important;
  }

  div.carousel__inner-slide {
    position: static;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  max-height: ${({ imageHeight }) => `${imageHeight}px`};
`;

const Metadata = styled.div`
  padding: 80px 0 0;

  ${minWidth.tablet`
    padding: 80px 40px 0;
  `}

  ${minWidth.hamburgerLarge`
    padding: 80px 80px 0;
  `}

  ${minWidth.largest`
    padding: 80px 140px 0;
  `}
`;

const ArrowButton = styled.button``;

const ArrowIcon = styled(Icon)`
  position: absolute;
  top: ${({ imageheight }) => `${imageheight / 2 - 15}px`};
  ${({ left }) => left && "left: -30px;"}
  ${({ right }) => right && "right: -30px;"}
  width: 30px;
  ${({ rotate }) => rotate && "transform: rotate(180deg);"}
  ${({ disabled }) => disabled && "cursor: not-allowed;"}

  .arrow {
    stroke-width: 6;
  }

  ${minWidth.mobile`
    top: ${({ imageheight }) => `${imageheight / 2 - 20}px`};
    ${({ left }) => left && "left: -40px;"}
    ${({ right }) => right && "right: -40px;"}
    width: 40px;
  `}

  ${minWidth.tablet`
    top: ${({ imageheight }) => `${imageheight / 2 - 30}px`};
    ${({ left }) => left && "left: -60px;"}
    ${({ right }) => right && "right: -60px;"}
    width: 60px;

    .arrow {
      stroke-width: 4;
    }
  `}

  ${minWidth.hamburgerLarge`
    top: ${({ imageheight }) => `${imageheight / 2 - 50}px`};
    ${({ left }) => left && "left: -100px;"}
    ${({ right }) => right && "right: -100px;"}
    width: 100px;

    .arrow {
      stroke-width: 2;
    }
  `}

  .arrow {
    transition: stroke 0.15s linear;
    ${({ disabled }) => disabled && `stroke: ${colors.disabled};`}
  }

  &:hover {
    .arrow {
      ${({ disabled }) => !disabled && `stroke: ${colors.text};`}
    }
  }
`;

const ArrowLeft = ({ imageHeight, ...props }) => (
  <ArrowButton {...props}>
    <ArrowIcon
      name="arrow"
      left="true"
      imageheight={imageHeight}
      disabled={props.disabled}
    />
  </ArrowButton>
);

const ArrowRight = ({ imageHeight, ...props }) => (
  <ArrowButton {...props}>
    <ArrowIcon
      name="arrow"
      right="true"
      rotate="true"
      imageheight={imageHeight}
      disabled={props.disabled}
    />
  </ArrowButton>
);

const DotWrapper = styled(DotGroup)`
  position: absolute;
  left: 50%;
  top: ${({ imageheight }) => `${imageheight + 20}px`};
  transform: translateX(-50%);
  display: flex;
  justify-content: center;

  .carousel__dot--selected {
    background-color: ${colors.salmon};
  }
`;

const Dot = styled.button`
  width: 6px;
  height: 6px;
  margin: 0 4px;
  border-radius: 50%;
  display: inline-block;
  border: 2px solid ${colors.salmon};
  background-color: ${({ isSelected }) =>
    isSelected ? colors.salmon : colors.background};
  transition: background-color 0.15s linear;

  &:hover {
    background-color: ${colors.salmon};
  }
`;

export default Gallery;
