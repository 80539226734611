import styled from "styled-components";

import { colors } from "variables";
import { minWidth } from "utils";

import illustrationImage1 from "images/illustrations/1.png";
import illustrationImage2 from "images/illustrations/2.png";
import illustrationImage3 from "images/illustrations/3.png";
import illustrationImage4 from "images/illustrations/4.png";

const illustrations = [
  { image: illustrationImage1, text: "your words" },
  { image: illustrationImage2, text: "searching the web" },
  { image: illustrationImage3, text: "generating" },
  { image: illustrationImage4, text: "unique pattern" }
];

const Illustrations = () => {
  return (
    <Wrapper>
      {illustrations.map((illustration) => (
        <Illustration key={illustration.text}>
          <Image
            src={illustration.image}
            alt={illustration.text}
            title={illustration.text}
          />
          <Text>{illustration.text}</Text>
        </Illustration>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Illustration = styled.div`
  flex: 1;
  text-align: center;

  ${minWidth.mobile`
    width: 50%;
    flex: 0 1 auto;
  `}

  ${minWidth.tablet`
    width: auto;
    flex: auto;
  `}
`;

const Image = styled.img`
  width: 160px;

  ${minWidth.mobile`
    width: 175px;
  `}

  ${minWidth.hamburgerLarge`
    width: auto;
  `}
`;

const Text = styled.div`
  color: ${colors.salmon};
  font-family: "Montserrat-Medium";
  font-size: 13px;
`;

export default Illustrations;
