import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { API } from "aws-amplify";
import { v4 as uuid4 } from "uuid";

import { minWidth, scrollToTop } from "utils";

import Header from "./Header";
import Footer from "./Footer";
import About from "./About";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

const DEFAULT_DATA = {
  text: "",
  width: 0,
  height: 0,
  size: ""
};

const Generator = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [data, setData] = useState(DEFAULT_DATA);
  const [doc, setDocument] = useState(null);
  const pollingId = useRef(0);
  const pollingCount = useRef(1);
  const loading = useRef(false);
  const [error, setError] = useState("");
  const [isAboutOpened, setAboutOpen] = useState(false);

  const startPolling = (id) => {
    if (!pollingId.current) {
      pollingId.current = setInterval(async () => {
        if (pollingCount.current === 30) {
          stopPolling();
          setError("Pattern generation was not successful. Try again later.");
          handleNextClick();
        } else {
          if (!loading.current) {
            await handleGetNewDocument(id);
            pollingCount.current += 1;
          }
        }
      }, 10 * 1000);
    }
  };

  const stopPolling = () => {
    if (pollingId.current) {
      clearInterval(pollingId.current);
      pollingId.current = 0;
    }
  };

  const handleNextClick = () => {
    setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
  };

  useEffect(() => {
    scrollToTop();
  }, [currentStep]);

  const handleGenerateDocument = () => {
    const newDocumentId = uuid4();
    try {
      API.post("api", "/documents", {
        body: {
          id: newDocumentId,
          ...data,
          text: data.text.replace(/\n/g, " ").replace(/  +/g, " ").trim()
        }
      });
      startPolling(newDocumentId);
    } catch (error) {}
  };

  const handleGetNewDocument = async (id) => {
    loading.current = true;

    try {
      const response = await API.get("api", `/documents/${id}`);
      setDocument(response);
      stopPolling();
      handleNextClick();
    } catch (error) {}

    loading.current = false;
  };

  const handleGenerateNewPattern = () => {
    setCurrentStep(1);
    setData(DEFAULT_DATA);
    setDocument(null);
    setError("");
    pollingCount.current = 1;
  };

  const steps = [
    <Step1 onNextClick={handleNextClick} />,
    <Step2
      data={data}
      setData={setData}
      onNextClick={() => {
        handleNextClick();
        handleGenerateDocument();
      }}
    />,
    <Step3 />,
    <Step4
      doc={doc}
      onGenerateNewPattern={handleGenerateNewPattern}
      error={error}
    />
  ];

  return (
    <>
      <Wrapper>
        <Header onAboutOpen={() => setAboutOpen(true)} />
        <Content>{steps[currentStep - 1]}</Content>
        <Footer />
        <About isOpened={isAboutOpened} onClose={() => setAboutOpen(false)} />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  overflow-x: hidden;
`;

const Content = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding-top: 87px;

  ${minWidth.tablet`
    padding-top: 132px;
  `}

  ${minWidth.hamburgerLarge`
    max-width: 1024px;
  `}

  ${minWidth.large`
    max-width: 1280px;
  `}

  ${minWidth.largest`
    max-width: 1400px;
  `}
`;

export default Generator;
