import styled from "styled-components";

import loader from "images/loader.svg";

const Loader = ({ className, isLoading = true, children }) =>
  isLoading ? (
    <Wrapper className={className}>
      <StyledLoader src={loader} alt="Loading…" title="Loading…" />
    </Wrapper>
  ) : (
    (children && children()) || null
  );

const Wrapper = styled.div`
  width: 100%;
  transform: translateY(100px);
  text-align: center;
`;

const StyledLoader = styled.img`
  transform: scale(1.25);
`;

export default Loader;
