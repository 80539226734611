import { useEffect, useRef } from "react";
import styled from "styled-components";

import { minWidth, useIsTabletOrHigher } from "utils";

import Illustrations from "./Illustrations";
import Icon from "components/Shared/Icon";

const Step3 = () => {
  const isTabletOrHigher = useIsTabletOrHigher();

  const refIndex = useRef(0);
  const refDirection = useRef("increment");

  const togglePattern = (idx, show) => {
    document.querySelector(`.pattern${idx}`).style.opacity = show ? 1 : 0;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      togglePattern(refIndex.current, refDirection.current === "increment");

      if (refIndex.current === 0 && refDirection.current === "decrement") {
        refDirection.current = "increment";
      } else if (
        refIndex.current === 8 &&
        refDirection.current === "increment"
      ) {
        refDirection.current = "decrement";
      } else {
        refIndex.current =
          refIndex.current + (refDirection.current === "increment" ? 1 : -1);
      }
    }, 3333);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Wrapper>
      <Title>
        {isTabletOrHigher ? "Generating your pattern…" : "Processing…"}
      </Title>
      <Subtitle>…it might take a minute</Subtitle>
      <Illustrations />
      <TitleParagraph>
        The collage of images and texts is beeing processed through algorithmic
        methods to create a final visual abstraction - a pattern which is unique
        and personalized according to your text.
      </TitleParagraph>
      <Pattern name="pattern" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  min-height: calc(100vh - 274px);
  padding: 10px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${minWidth.largest`
    padding: 50px 0 0;
  `}
`;

const Title = styled.div`
  font-family: "Montserrat-Medium";
  font-size: 40px;
  line-height: 1.2;
  text-align: center;

  ${minWidth.tablet`
    font-size: 48px;
  `}
`;

const Subtitle = styled.div`
  margin: 10px 0;
  font-family: "Montserrat-Medium";
  font-size: 12px;
  line-height: 1.2;
  text-align: center;

  ${minWidth.tablet`
    margin: 20px 0;
    font-size: 14px;
  `}
`;

const TitleParagraph = styled.p`
  max-width: 750px;
  margin-top: 50px;
  font-size: 22px;
  line-height: 1.3;
  text-align: center;

  ${minWidth.tablet`
    font-size: 26px;
  `}
`;

const Pattern = styled(Icon)`
  position: absolute;
  top: -87px;
  left: -25%;
  width: 150%;
  z-index: -10;

  ${minWidth.mobile`
    position: fixed;
    top: 0;
    left: 0;
    width: 105%;
  `}

  ${minWidth.hamburgerLarge`
    width: 100%;
  `}
`;

export default Step3;
