import styled from "styled-components";

import { colors } from "variables";
import {
  minWidth,
  useIsMobileMOrHigher,
  useIsMobileOrHigher,
  useIsTabletOrHigher,
  useIsHamburgerLargeOrHigher,
  useIsLargeOrHigher,
  useIsLargestOrHigher
} from "utils";

import Illustrations from "./Illustrations";
import Gallery from "components/Shared/Gallery";
import Icon from "components/Shared/Icon";

import { kilims, stories } from "data/slideshows";

const Step1 = ({ onNextClick }) => {
  const isMobileMOrHigher = useIsMobileMOrHigher();
  const isMobileOrHigher = useIsMobileOrHigher();
  const isTabletOrHigher = useIsTabletOrHigher();
  const isHamburgerLargeOrHigher = useIsHamburgerLargeOrHigher();
  const isLargeOrHigher = useIsLargeOrHigher();
  const isLargestOrHigher = useIsLargestOrHigher();

  const slideshowImageHeight = isLargestOrHigher
    ? 750
    : isLargeOrHigher
    ? 694
    : isHamburgerLargeOrHigher
    ? 500
    : isTabletOrHigher
    ? 400
    : isMobileOrHigher
    ? 300
    : isMobileMOrHigher
    ? 200
    : 170;

  return (
    <Wrapper>
      <GalleryWrapper moveUp>
        <Gallery
          slides={kilims}
          interval={3000}
          infinite
          naturalSlideWidth={100}
          naturalSlideHeight={66.66}
          imageHeight={slideshowImageHeight}
        />
        <SloganLeft name="slogan-1" />
        <SloganRight name="slogan-2" />
      </GalleryWrapper>
      <Title moveUp>
        Write them once.
        <br />
        Live them forever.
      </Title>
      <TitleParagraph>
        The Pattern Generator is an ever growing worldwide collection of
        made-to-order carpets. Each piece is a one-off, conceived from your own
        words, rendered into a unique pattern by a computer algorithm and
        crafted by the experienced women in our fair trade weaving mill in
        Bosnia.
      </TitleParagraph>
      <Illustrations />
      <ButtonWrapper type="button" onClick={onNextClick}>
        <Icon name="button-create" />
      </ButtonWrapper>
      <CreationParagraphs>
        <ParagraphWrapper>
          <ParagraphTitle>Co-creation</ParagraphTitle>
          <Paragraph>
            We are happy to present a new approach to creating our kilims, a way
            that combines modern technologies with tradition. It’s a platform
            that involves you in the production process through a
            computer-controlled co-creation of the final product. To create your
            own pattern you think of the text and write it the program. Your
            words will be used as a search key for downloading content from the
            Web and creating a digital collage. The collage of images and texts
            is then processed through algorithmic methods to create a final
            visual abstraction - a pattern which is unique and personalized
            according to your text.
          </Paragraph>
        </ParagraphWrapper>
        <ParagraphWrapper>
          <ParagraphTitle>Production</ParagraphTitle>
          <Paragraph>
            After morphing your written words into a one-of-a-kind pattern, the
            hand work begins. Using only the best locally sourced materials,
            natural dyes and centuries-old weaving techniques, our team of
            skilled ladies from Visoko, Bosnia will get to work. One thread, one
            ball, one weave at a time. That is why our kilims take time. You can
            expect yours to be finished in 3 to 8 weeks depending on the size,
            and shipped to you as soon as possible.
          </Paragraph>
        </ParagraphWrapper>
      </CreationParagraphs>
      <Title>Stories from happy owners</Title>
      <GalleryWrapper>
        <Gallery
          slides={stories.map((story) => ({
            ...story,
            metadata: (
              <>
                <ParagraphTitle
                  dangerouslySetInnerHTML={{ __html: story.title }}
                />
                <Paragraph dangerouslySetInnerHTML={{ __html: story.text }} />
              </>
            )
          }))}
          interval={3000}
          infinite
          naturalSlideWidth={100}
          naturalSlideHeight={66.66}
          imageHeight={slideshowImageHeight}
        />
      </GalleryWrapper>
      <Disclaimer>
        Your words are safe with us - we will never disclose them to anyone or
        use them to make another kilim. However, some of you allowed us to get a
        glimpse of your experience with the pattern generator.
      </Disclaimer>
      <ButtonWrapper type="button" onClick={onNextClick}>
        <Icon name="button-create" />
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: calc(100vh - 274px);
  padding: 25px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${minWidth.largest`
    padding: 50px 0 0;
  `}
`;

const GalleryWrapper = styled.div`
  position: relative;
  width: calc(100% - 20px);

  ${minWidth.mobile`
    width: calc(100% - 80px);
  `}

  ${minWidth.tablet`
    width: calc(100% - 120px);
    ${({ moveUp }) => moveUp && "transform: translateY(-50px);"}
  `}

  ${minWidth.hamburgerLarge`
    width: calc(100% - 200px);
  `}
`;

const SloganLeft = styled(Icon)`
  position: absolute;
  top: 10px;
  left: calc(50% - 170px);
  width: 150px;

  ${minWidth.mobileM`
    left: calc(50% - 190px);
  `}

  ${minWidth.mobile`
    top: 25px;
    left: calc(50% - 265px);
  `}

  ${minWidth.tablet`
    top: 30px;
    left: calc(50% - 360px);
    width: 216px;
  `}

  ${minWidth.hamburgerLarge`
    top: 60px;
    left: calc(50% - 435px);
  `}

  ${minWidth.largest`
    top: 110px;
    left: calc(50% - 600px);
  `}
`;

const SloganRight = styled(Icon)`
  position: absolute;
  top: -30px;
  left: calc(50% - 10px);
  width: 162px;

  ${minWidth.mobile`
    left: 50%;
  `}

  ${minWidth.mobileM`
    left: calc(50% + 75px);
  `}

  ${minWidth.tablet`
    width: 234px;
  `}

  ${minWidth.hamburgerLarge`
    left: calc(50% + 160px);
  `}

  ${minWidth.largest`
    left: calc(50% + 335px);
  `}
`;

const Title = styled.div`
  margin: 60px 0 40px;
  font-family: "Montserrat-Medium";
  font-size: 36px;
  line-height: 1.2;
  text-align: center;

  ${minWidth.tablet`
    margin: ${({ moveUp }) => (moveUp ? "10px 0 60px" : "60px 0")};
    font-size: 48px;
  `}
`;

const TitleParagraph = styled.p`
  width: calc(100% - 20px);
  max-width: 100%;
  margin-bottom: 40px;
  font-family: "Montserrat-Medium";
  font-size: 18px;
  line-height: 1.5;
  text-align: center;

  ${minWidth.mobile`
    width: calc(100% - 80px);
  `}

  ${minWidth.tablet`
    width: calc(100% - 120px);
    padding: 0 40px;
    font-size: 24px;
  `}

  ${minWidth.hamburgerLarge`
    width: calc(100% - 200px);
    padding: 0 80px;
  `}

  ${minWidth.largest`
    padding: 0 140px;
  `}
`;

const CreationParagraphs = styled.div`
  margin: 0 0 40px;

  ${minWidth.tablet`
    margin: 0 10% 60px;
    display: flex;
  `}
`;

const ParagraphWrapper = styled.div`
  &:first-of-type {
    margin-bottom: 40px;
  }

  ${minWidth.tablet`
    flex: 1;

    &:first-of-type {
      margin-right: 40px;
    }

    &:last-of-type {
      margin-left: 40px;
    }
  `}
`;

const ParagraphTitle = styled.div`
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;

  ${minWidth.tablet`
    font-size: 22px;
  `}
`;

const Paragraph = styled.p`
  margin: 0;
  font-size: 20px;
  line-height: 1.4;
  text-align: left;

  ${minWidth.tablet`
    font-size: 22px;
  `}
`;

const Disclaimer = styled(Paragraph)`
  width: 100%;
  margin-top: 20px;
  align-self: flex-end;
  font-family: "CrimsonText-Italic";
  font-size: 18px;
  line-height: 1.3;
  text-align: justify;

  ${minWidth.tablet`
    width: calc(40% - 40px);
    margin: 20px 10% 0;
  `}
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 60px 0;
  transition: transform 0.15s linear;

  ${minWidth.mobileM`
    width: 100%;
    max-width: 450px;
  `}

  .button-create-text {
    transition: fill 0.15s linear;
  }

  &:hover {
    transform: rotate(-15deg);

    .button-create-text {
      fill: ${colors.salmon};
    }
  }
`;

export default Step1;
