import styled from "styled-components";

import { colors } from "variables";
import { minWidth } from "utils";

import Icon from "components/Shared/Icon";

const Header = ({ onAboutOpen, ...props }) => {
  return (
    <Wrapper {...props}>
      <Container>
        <Logo name="logo" alt="About" title="About" onClick={onAboutOpen} />
        <Title>Pattern Generator</Title>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 87px;
  z-index: 10;

  ${minWidth.tablet`
    height: 132px;
    padding: 10px 0;
  `}

  ${minWidth.hamburgerLarge`
    padding: 10px 8vw;
  `}
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
`;

const Logo = styled(Icon)`
  position: absolute;
  top: 0;
  left: 0;
  width: 135px;
  cursor: pointer;
  transition: filter 0.15s linear;

  ${minWidth.tablet`
    width: 175px;
  `}

  .logo {
    transition: fill 0.15s linear;
  }

  &:hover {
    .logo {
      fill: ${colors.salmon};
    }
  }
`;

const Title = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 150px;
  font-size: 24px;
  line-height: 1.2;
  text-align: right;
  text-transform: uppercase;

  ${minWidth.tablet`
    top: auto;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: auto;
    font-size: 28px;
    line-height: unset;
    text-align: center;
  `}
`;

export default Header;
