const REGION = "eu-west-1";

const local = {
  apiGateway: {
    REGION,
    URL: "http://localhost:4000"
  }
};

const development = {
  apiGateway: {
    REGION,
    URL: "https://44ail3gbok.execute-api.eu-west-1.amazonaws.com/development"
  },
  cognito: {
    REGION,
    USER_POOL_ID: "eu-west-1_a5LENZTs2",
    APP_CLIENT_ID: "5l85gqdn8cah3fpgt42gn7g7kl",
    IDENTITY_POOL_ID: "eu-west-1:97db628e-da01-4539-ac05-2365883e9461"
  }
};

const production = {
  apiGateway: {
    REGION,
    URL: "https://iqlokbu40j.execute-api.eu-west-1.amazonaws.com/production"
  },
  cognito: {
    REGION,
    USER_POOL_ID: "eu-west-1_nXKcttSlB",
    APP_CLIENT_ID: "2ph51rvl2mscbgeeuams4t1ut5",
    IDENTITY_POOL_ID: "eu-west-1:f35df1d6-25bf-41ac-8601-0d445f91d1f0"
  }
};

// Default to local if stage not set
const stage = process.env.REACT_APP_STAGE || "local";
const config =
  stage === "production"
    ? production
    : stage === "development"
    ? development
    : local;

const settings = {
  // Add common config values here
  stage,
  ...config
};

export default settings;
