import styled from "styled-components";

import { colors } from "variables";
import { minWidth } from "utils";

const Error = ({ className, children }) => (
  <Container className={className}>{children}</Container>
);

const Container = styled.div`
  color: ${colors.error};
  font-family: "Montserrat-Medium";
  font-size: 14px;

  ${minWidth.tablet`
    font-size: 16px;
  `}
`;

export default Error;
