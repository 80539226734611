import { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";

import { colors } from "variables";
import { minWidth } from "utils";

import Error from "components/Shared/Error";
import Icon from "components/Shared/Icon";
import Input from "components/Shared/Input";

import linesImage from "images/lines.svg";
import linesMobileImage from "images/lines-mobile.svg";

const Step2 = ({ data, setData, onNextClick }) => {
  const { text } = data;
  const [textError, setTextError] = useState("");

  const handleTextChange = (value) => {
    setData((prevData) => ({
      ...prevData,
      text: value
    }));
  };

  useEffect(() => {
    if (text) {
      setTextError("");

      const cleanedText = text.replace(/\n/g, " ").replace(/  +/g, " ").trim();
      const nWords = cleanedText.split(" ").length;

      if (nWords < 5) {
        setTextError(`Minimum number of words is 5 (currently: ${nWords}).`);
        return;
      }

      if (nWords > 100) {
        setTextError(`Maximum number of words is 100 (currently: ${nWords}).`);
        return;
      }

      const nCharacters = text.length;

      if (nCharacters > 750) {
        setTextError(
          `Maximum number of characters is 750 (currently: ${nCharacters}).`
        );
        return;
      }
    }
  }, [text]);

  const { width, height } = data;
  const [selectedKilim, setSelectedKilim] = useState("");
  const [customWidth, setCustomWidth] = useState("");
  const [customHeight, setCustomHeight] = useState("");
  const [sizeError, setSizeError] = useState("");
  const refWidthInput = useRef(null);

  const handleSizeChange = ({ width, height, size }) => {
    setData((prevData) => ({
      ...prevData,
      ...(width !== undefined && { width }),
      ...(height !== undefined && { height }),
      ...(size !== undefined && { size })
    }));
  };

  const resetSize = () => {
    handleSizeChange({ width: 0, height: 0, size: "" });
    setCustomWidth("");
    setCustomHeight("");
  };

  useEffect(() => {
    setSizeError("");

    if (selectedKilim === "custom" && (!Boolean(width) || !Boolean(height))) {
      setSizeError("Width and height are required.");
      return;
    }

    if (width && width < 10) {
      setSizeError("Minimum width is 10 cm.");
      return;
    }

    if (height && height < 10) {
      setSizeError("Minimum height is 10 cm.");
      return;
    }

    if (width && width % 10 !== 0) {
      setSizeError("Width has to be a multiple of 10 cm.");
      return;
    }

    if (height && height % 10 !== 0) {
      setSizeError("Height has to be a multiple of 10 cm.");
      return;
    }

    if (width && height && width > height) {
      setSizeError(
        "Shape of the pattern has to be vertical (width <= height)."
      );
      return;
    }
  }, [selectedKilim, width, height]);

  return (
    <Wrapper>
      <Title>Type in your text</Title>
      <TitleParagraph>
        It can be anything from the lyrics of that one special song, your
        favourite poem, your most intimate thought…
        <br />
        or just any words that you would like to leave a lasting mark in your
        home and in your life.
      </TitleParagraph>
      {textError ? (
        <TextareaError>{textError}</TextareaError>
      ) : (
        <TextareaTitle>(5 to 100 words)</TextareaTitle>
      )}
      <TextareaWrapper>
        <Textarea
          value={text}
          onChange={(event) => handleTextChange(event.target.value)}
        />
      </TextareaWrapper>
      <Title>Select size</Title>
      <Kilims>
        <Kilim
          onClick={() => {
            resetSize();
            handleSizeChange({ width: 70, height: 100, size: "Medium" });
            setSelectedKilim("medium");
          }}
          isSelected={selectedKilim === "medium"}
        >
          <KilimImageWrapper>
            <KilimImage
              name="kilim-medium"
              alt="70 x 100 cm"
              title="70 x 100 cm"
            />
          </KilimImageWrapper>
          <SizeLabel>
            Medium
            <br />
            70 x 100 cm
          </SizeLabel>
        </Kilim>
        <Kilim
          onClick={() => {
            resetSize();
            handleSizeChange({ width: 140, height: 200, size: "Large" });
            setSelectedKilim("large");
          }}
          isSelected={selectedKilim === "large"}
        >
          <KilimImageWrapper>
            <KilimImage
              name="kilim-large"
              alt="140 x 200 cm"
              title="140 x 70 cm"
            />
          </KilimImageWrapper>
          <SizeLabel>
            Large
            <br />
            140 x 200 cm
          </SizeLabel>
        </Kilim>
        <Kilim
          onClick={() => {
            resetSize();
            handleSizeChange({ width: 200, height: 300, size: "XLarge" });
            setSelectedKilim("xlarge");
          }}
          isSelected={selectedKilim === "xlarge"}
        >
          <KilimImageWrapper>
            <KilimImage
              name="kilim-xlarge"
              alt="200 x 300 cm"
              title="200 x 300 cm"
            />
          </KilimImageWrapper>
          <SizeLabel>
            XLarge
            <br />
            200 x 300 cm
          </SizeLabel>
        </Kilim>
        <Kilim
          onClick={() => {
            if (selectedKilim !== "custom") {
              resetSize();
              handleSizeChange({ size: "Custom size" });
              setSelectedKilim("custom");
              refWidthInput.current.focus();
            }
          }}
          isSelected={selectedKilim === "custom"}
        >
          <KilimImageWrapper>
            <KilimImage
              name="kilim-custom"
              alt="Custom size"
              title="Custom size"
            />
          </KilimImageWrapper>
          <SizeLabel>Custom size</SizeLabel>
          <Inputs>
            <SizeInput
              ref={refWidthInput}
              name="width"
              type="number"
              placeholder="Width"
              min="70"
              step="10"
              value={customWidth}
              onChange={(event) => {
                const currentWidth = parseInt(event.target.value);
                handleSizeChange({ width: currentWidth });
                setCustomWidth(currentWidth);
              }}
            />
            <SizeInput
              name="height"
              type="number"
              placeholder="Height"
              min="70"
              step="10"
              value={customHeight}
              onChange={(event) => {
                const currentHeight = parseInt(event.target.value);
                handleSizeChange({ height: currentHeight });
                setCustomHeight(currentHeight);
              }}
            />
            <SizeError>{sizeError}</SizeError>
          </Inputs>
        </Kilim>
      </Kilims>
      <ButtonWrapper
        type="button"
        onClick={onNextClick}
        disabled={
          !text ||
          Boolean(textError) ||
          !Boolean(selectedKilim) ||
          Boolean(sizeError)
        }
      >
        <Icon name="button-generate" />
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: calc(100vh - 274px);
  padding: 10px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${minWidth.largest`
    padding: 50px 0 0;
  `}
`;

const Title = styled.div`
  margin: 0 0 40px;
  font-family: "Montserrat-Medium";
  font-size: 40px;
  line-height: 1.2;
  text-align: center;

  ${minWidth.tablet`
    font-size: 48px;
  `}
`;

const TitleParagraph = styled.p`
  max-width: 100%;
  font-family: "Montserrat-Medium";
  font-size: 18px;
  line-height: 1.5;
  text-align: center;

  ${minWidth.tablet`
    max-width: 750px;
    font-size: 24px;
  `}
`;

const TextareaTitle = styled.div`
  margin: 40px auto 10px;
  font-family: "Montserrat-Medium";
  font-size: 14px;

  ${minWidth.tablet`
    margin: 60px auto 10px;
    font-size: 16px;
  `}
`;

const TextareaError = styled(Error)`
  margin: 60px auto 10px;
`;

const TextareaWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 700px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  background: url(${linesMobileImage});
  background-repeat: repeat-x;

  ${minWidth.hamburgerLarge`
    max-width: 1000px;
    height: 525px;
    margin-bottom: 100px;
    background: url(${linesImage});
  `}
`;

const Textarea = styled.textarea`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
  outline: none;
  box-shadow: none;
  resize: none;
  color: ${colors.tautara};
  font-size: 18px;
  line-height: 40px;
  background: transparent;

  ${minWidth.hamburgerLarge`
    font-size: 22.5px;
    line-height: 50px;
  `}
`;

const Kilims = styled.div`
  width: 100%;

  ${minWidth.tablet`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  `}
`;

const KilimImageWrapper = styled.div`
  width: 100%;
  transform: scale(0.75);

  ${minWidth.tablet`
    width: auto;
    height: 350px;
    display: flex;
    align-items: center;
  `}

  ${minWidth.largest`
    height: 395px;
    transform: scale(1);
  `}
`;

const KilimImage = styled(Icon)`
  width: 100%;
  transition: transform 0.15s linear;
`;

const SizeLabel = styled.div`
  font-size: 24px;
  text-align: center;
  transition: text-decoration 0.15s linear;

  ${minWidth.hamburgerLarge`
    margin-top: 10px;
  `}

  ${minWidth.largest`
    margin-top: 20px;
  `}
`;

const Kilim = styled.div`
  margin-bottom: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  ${minWidth.tablet`
    width: 50%;
    flex: 0 1 auto;
  `}

  ${minWidth.desktopL`
    width: auto;
    margin: 0;
    flex: auto;
  `}

  .kilim {
    transition: fill 0.15s linear;
  }

  .kilim-price {
    opacity: 0;
    transition: opacity 0.15s linear;
  }

  &:hover {
    .kilim {
      fill: ${colors.salmon};
    }

    .kilim-price {
      opacity: 1;
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      ${KilimImage} {
        transform: rotate(20deg);
      }

      ${SizeLabel} {
        font-family: "CrimsonText-Italic";
        text-decoration: underline;
        text-decoration-color: ${colors.salmon};
        text-underline-offset: 5px;
      }

      .kilim {
        fill: ${colors.salmon};
      }

      .kilim-price {
        opacity: 1;
      }
    `}
`;

const Inputs = styled.div`
  width: 80%;
  height: 153px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
`;

const SizeInput = styled(Input)`
  width: 100%;
  margin: 5px 0;
`;

const SizeError = styled(Error)`
  margin-top: 5px;
  align-self: center;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 60px 0;
  transition: transform 0.15s linear;

  ${minWidth.mobileM`
    width: 100%;
    max-width: 450px;
  `}

  .button-generate-text {
    transition: fill 0.15s linear;
  }

  &:hover {
    transform: rotate(-15deg);

    .button-generate-text {
      fill: ${colors.salmon};
    }
  }

  &[disabled] {
    pointer-events: none;

    .button-generate-text {
      fill: ${colors.disabled};
    }

    .button-generate-line {
      stroke: ${colors.disabled};
    }
  }
`;

export default Step2;
