import styled from "styled-components";

import { colors } from "variables";
import { minWidth } from "utils";

const Footer = () => {
  return (
    <Wrapper>
      <LinkWrapper>
        <Link href="mailto:ciao@kobeiagikilims.com">
          ciao@kobeiagikilims.com
        </Link>
      </LinkWrapper>
      <LinkWrapper>
        <Link
          href="https://www.instagram.com/kobeiagi_kilims/"
          target="_blank"
          rel="noreferrer"
        >
          Instagram
        </Link>
      </LinkWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin-top: 80px;
  padding-bottom: 30px;
  text-align: center;

  ${minWidth.tablet`
    margin-top: 40px;
    padding: 0 8vw 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;

const LinkWrapper = styled.div``;

const Link = styled.a`
  flex: 1;
  color: ${colors.text};
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  transition: color 0.15s linear;

  ${minWidth.mobileM`
    font-size: 24px;
  `}

  ${minWidth.tablet`
    font-size: 28px;
  `}

  &:hover {
    color: ${colors.salmon};
  }
`;

export default Footer;
