import { useState, useCallback } from "react";
import styled from "styled-components";
import { API } from "aws-amplify";

import { colors } from "variables";
import {
  minWidth,
  useIsTabletOrHigher,
  scrollToTop,
  isEmailValid,
  sizeToPrice
} from "utils";

import Error from "components/Shared/Error";
import Gallery from "components/Shared/Gallery";
import Icon from "components/Shared/Icon";
import Input from "components/Shared/Input";

const DEFAULT_CUSTOMER = {
  fullName: "",
  address: "",
  zipCode: "",
  city: "",
  state: "",
  country: "",
  email: ""
};

const Step4 = ({ doc, onGenerateNewPattern, error }) => {
  const isTabletOrHigher = useIsTabletOrHigher();

  const [swiped, setSwiped] = useState(false);
  const [orderMode, setOrderMode] = useState(false);
  const [postOrderMode, setPostOrderMode] = useState(false);
  const [customer, setCustomer] = useState(DEFAULT_CUSTOMER);
  const [customerError, setCustomerError] = useState("");
  const [isDownloaded, setDownloaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const generatedImages = [
    { image: doc.pattern_url_1, alt: "Generated pattern 1" },
    { image: doc.pattern_url_2, alt: "Generated pattern 2" },
    { image: doc.pattern_url_3, alt: "Generated pattern 3" },
    { image: doc.pattern_url, alt: "Generated pattern 4" },
    { image: doc.image_url, alt: "Kilim image" }
  ];

  const handleLastSlide = useCallback(() => {
    setSwiped(true);
  }, []);

  const handleCustomerChange = (event) => {
    setCustomer((prevCustomerData) => ({
      ...prevCustomerData,
      [event.target.name]: event.target.value
    }));
  };

  const handleOrderConfirmClick = async (event) => {
    event.preventDefault();

    setCustomerError("");

    if (!customer.fullName) {
      setCustomerError("Full name is required.");
      return;
    }

    if (!customer.address) {
      setCustomerError("Address is required.");
      return;
    }

    if (!customer.zipCode) {
      setCustomerError("Zip / postal code is required.");
      return;
    }

    if (!customer.city) {
      setCustomerError("City is required.");
      return;
    }

    if (!customer.country) {
      setCustomerError("Country is required.");
      return;
    }

    if (!customer.email) {
      setCustomerError("E-mail is required.");
      return;
    }

    if (!isEmailValid(customer.email)) {
      setCustomerError("E-mail is not valid.");
      return;
    }

    setLoading(true);

    try {
      await API.patch("api", `/documents/${doc.id}/customer`, {
        body: {
          customer_name: customer.fullName,
          customer_address: customer.address,
          customer_zip_code: customer.zipCode,
          customer_city: customer.city,
          ...(customer.state && { customer_state: customer.state }),
          customer_country: customer.country,
          customer_email: customer.email,
          ordered: true
        }
      });
      if (!isDownloaded) {
        await handleDownload();
        setDownloaded(true);
      }
      scrollToTop();
      setOrderMode(false);
      setPostOrderMode(true);
    } catch (error) {
      setCustomerError(error.response.data.message);
    }

    setLoading(false);
  };

  const handleOrderCancelClick = (event) => {
    event.preventDefault();

    scrollToTop();
    setCustomer(DEFAULT_CUSTOMER);
    setCustomerError("");
    setOrderMode(false);
  };

  const handleDownload = async () => {
    setLoading(true);

    try {
      const response = await fetch(doc.pdf_url, { method: "GET" });
      const buffer = await response.arrayBuffer();
      const blob = new Blob([buffer], {
        type: "application/pdf"
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${doc.size} kilim (${doc.id}).pdf`);
      if (typeof link.download === "undefined") {
        link.setAttribute("target", "_blank");
      }
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const formElements = [
    { name: "fullName", label: "full name*: ", type: "text" },
    { name: "address", label: "address*: ", type: "text" },
    {
      name: "zipCode",
      label: isTabletOrHigher ? "zip / postal code*: " : "zip code*: ",
      type: "text"
    },
    { name: "city", label: "city*: ", type: "text" },
    { name: "state", label: "state: ", type: "text" },
    { name: "country", label: "country*: ", type: "text" },
    { name: "email", label: "e-mail*: ", type: "email" }
  ];

  return (
    <Wrapper>
      {error ? (
        <StyledError>{error}</StyledError>
      ) : (
        <>
          <Title
            dangerouslySetInnerHTML={{
              __html:
                orderMode || postOrderMode
                  ? "A unique pattern generated from<br />your words"
                  : swiped
                  ? "Your kilim is ready!"
                  : "Generated patterns"
            }}
          />
          {!orderMode && !postOrderMode && (
            <SubtitleSmall>
              {swiped ? "" : "Swipe right to see the process"}
            </SubtitleSmall>
          )}
          {(orderMode || postOrderMode) && (
            <Subtitle
              dangerouslySetInnerHTML={{
                __html: orderMode
                  ? "We are more than happy to make this beautiful kilim for you.<br />Please entrust us with your contact details."
                  : "We thank you for ordering your kilim!"
              }}
            />
          )}
          {orderMode || postOrderMode ? (
            <KilimImage
              src={doc.image_url}
              alt="Kilim image"
              title="Kilim image"
            />
          ) : (
            <GalleryWrapper>
              <Gallery
                slides={generatedImages}
                interval={3000}
                naturalSlideWidth={100}
                naturalSlideHeight={66.66}
                imageHeight={isTabletOrHigher ? 600 : 300}
                onLastSlide={handleLastSlide}
              />
            </GalleryWrapper>
          )}
          {swiped &&
            (orderMode ? (
              <Form onSubmit={handleOrderConfirmClick}>
                {formElements.map(({ name, label, type }) => (
                  <FormElement key={name}>
                    <InputLabel htmlFor={`input-${name}`}>{label}</InputLabel>
                    <CustomerInput
                      type={type}
                      id={`input-${name}`}
                      name={name}
                      value={customer[name]}
                      onChange={handleCustomerChange}
                    />
                  </FormElement>
                ))}
                <CustomerError>{customerError}</CustomerError>
                <ButtonOrderWrapper type="submit" disabled={loading}>
                  <Icon name="button-order" />
                </ButtonOrderWrapper>
                <ButtonSmall
                  onClick={handleOrderCancelClick}
                  disabled={loading}
                >
                  Cancel
                </ButtonSmall>
              </Form>
            ) : postOrderMode ? (
              <OrderConfirmation>
                <Disclaimer>
                  We will send you the order confirmation shortly, along with a
                  proforma invoice. As soon as it is paid, our weavers will get
                  to work. We hope you don’t mind if we will keep you posted on
                  the progress with a photo or a short message every now and
                  then.
                </Disclaimer>
                <ButtonSmall onClick={onGenerateNewPattern}>
                  Generate new
                </ButtonSmall>
              </OrderConfirmation>
            ) : (
              <Metadata>
                <Columns>
                  <Column>
                    <Label>
                      {doc.size}
                      <br />
                      {doc.width} x {doc.height} cm
                      <br />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: sizeToPrice[doc.size]
                        }}
                      />
                    </Label>
                    <Paragraph>{doc.text}</Paragraph>
                  </Column>
                  <Column>
                    <Label>
                      WEAVING TIME
                      <br />
                      Medium kilim &bull; 1 week
                      <br />
                      Large kilim &bull; 4 weeks
                      <br />
                      Xlarge kilim &bull; 6 weeks
                    </Label>
                    <Label>
                      WEIGHT
                      <br />
                      Medium kilim &bull; 700 g
                      <br />
                      Large kilim &bull; 2500 g
                      <br />
                      Xlarge kilim &bull; 6000 g
                    </Label>
                    <Label>
                      ORIGIN, MATERIAL, TECHNIQUE
                      <br />
                      Designed in Slovenia &bull; Made in Bosnia
                      <br />
                      Hand-weaving, Organic wool
                    </Label>
                  </Column>
                </Columns>
                <Buttons>
                  <ButtonsColumn>
                    <ButtonOrderWrapper
                      type="button"
                      onClick={() => {
                        scrollToTop();
                        setOrderMode(true);
                      }}
                    >
                      <Icon name="button-order" />
                    </ButtonOrderWrapper>
                  </ButtonsColumn>
                  <ButtonsColumn>
                    <ButtonDownloadWrapper
                      type="button"
                      onClick={() => {
                        handleDownload();
                        setDownloaded(true);
                      }}
                      disabled={loading}
                    >
                      <Icon name="button-download" />
                    </ButtonDownloadWrapper>
                    <ButtonSmall onClick={onGenerateNewPattern}>
                      Generate new
                    </ButtonSmall>
                  </ButtonsColumn>
                </Buttons>
              </Metadata>
            ))}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: calc(100vh - 274px);
  padding: 10px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${minWidth.largest`
    padding: 50px 0 0;
  `}
`;

const StyledError = styled(Error)`
  margin-top: 150px;
`;

const Title = styled.div`
  font-family: "Montserrat-Medium";
  font-size: 33px;
  line-height: 1.2;
  text-align: center;

  ${minWidth.tablet`
    font-size: 48px;
  `}
`;

const SubtitleSmall = styled.div`
  height: 40px;
  margin: 20px 0 10px;
  font-family: "Montserrat-Medium";
  font-size: 12px;
  line-height: 1.2;
  text-align: center;

  ${minWidth.tablet`
    font-size: 14px;
  `}
`;

const Subtitle = styled.div`
  margin: 30px 0 50px;
  font-family: "Montserrat-Medium";
  font-size: 20px;
  line-height: 1.2;
  text-align: center;

  ${minWidth.tablet`
    font-size: 24px;
  `}
`;

const GalleryWrapper = styled.div`
  position: relative;
  width: calc(100% - 30px);
  margin-bottom: 40px;

  ${minWidth.tablet`
    width: calc(100% - 200px);
  `}
`;

const Metadata = styled.div`
  width: 100%;

  ${minWidth.tablet`
    max-width: 900px;
  `}
`;

const Columns = styled.div`
  ${minWidth.tablet`
    display: flex;
  `}
`;

const Column = styled.div`
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${minWidth.tablet`
    padding-bottom: 100px;
    flex: 1;

    &:first-of-type {
      margin-right: 25px;
    }

    &:last-of-type {
      margin-left: 25px;
    }
  `}
`;

const Label = styled.div`
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;

  ${minWidth.tablet`
    font-size: 22px;
  `}
`;

const Paragraph = styled.p`
  margin: 0;
  font-size: 20px;
  line-height: 1.4;
  text-align: left;

  ${minWidth.tablet`
    font-size: 22px;
  `}
`;

const Buttons = styled.div`
  ${minWidth.tablet`
    display: flex;
  `}
`;

const ButtonsColumn = styled(Column)`
  padding-bottom: 0;
  align-items: center;
  justify-content: flex-start;

  ${minWidth.tablet`
    padding-bottom: 100px;
  `}
`;

const ButtonWrapper = styled.button`
  width: 100%;
  transition: transform 0.15s linear;

  ${minWidth.mobileM`
    width: 100%;
    max-width: 450px;
  `}

  &:hover {
    transform: rotate(-15deg);
  }

  &[disabled] {
    pointer-events: none;
  }
`;

const ButtonOrderWrapper = styled(ButtonWrapper)`
  .button-order-text {
    transition: fill 0.15s linear;
  }

  &:hover {
    .button-order-text {
      fill: ${colors.salmon};
    }
  }

  &[disabled] {
    .button-order-text {
      fill: ${colors.disabled};
    }

    .button-order-line {
      stroke: ${colors.disabled};
    }
  }
`;

const ButtonDownloadWrapper = styled(ButtonWrapper)`
  .button-download-text {
    transition: fill 0.15s linear;
  }

  &:hover {
    .button-download-text {
      fill: ${colors.salmon};
    }
  }

  &[disabled] {
    .button-download-text {
      fill: ${colors.disabled};
    }

    .button-download-line {
      stroke: ${colors.disabled};
    }
  }
`;

const ButtonSmall = styled.button`
  margin-top: 15px;
  font-family: "CrimsonText-Italic";
  font-size: 22px;
  text-decoration: underline;
  text-decoration-color: ${colors.salmon};
  text-underline-offset: 5px;
  transition: color 0.15s linear;

  &:hover {
    color: ${colors.salmon};
  }

  &[disabled] {
    pointer-events: none;
    color: ${colors.disabled};
    text-decoration-color: ${colors.disabled};
  }
`;

const KilimImage = styled.img`
  height: 300px;
  margin-bottom: 30px;

  ${minWidth.tablet`
    height: 400px;
  `}
`;

const Form = styled.form`
  width: 100%;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${minWidth.tablet`
    max-width: 1000px;
  `}
`;

const FormElement = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${colors.text};
`;

const InputLabel = styled.label`
  width: 127px;
  margin: 0;
  font-size: 20px;

  ${minWidth.tablet`
    width: 250px;
    font-size: 24px;
  `}
`;

const CustomerInput = styled(Input)`
  width: 100%;
  border: none;
  color: ${colors.black};
  font-family: "CrimsonText-Regular";
  font-size: 20px;

  ${minWidth.tablet`
    font-size: 24px;
  `}
`;

const CustomerError = styled(Error)`
  height: 24px;
  margin: 20px 0 10px;
`;

const OrderConfirmation = styled.div`
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Disclaimer = styled.p`
  margin: 20px 0 30px;
  max-width: 750px;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;

  ${minWidth.tablet`
    font-size: 24px;
  `}
`;

export default Step4;
