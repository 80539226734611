import slideshow1image1 from "images/slideshow1/1.png";
import slideshow1image2 from "images/slideshow1/2.png";
import slideshow1image3 from "images/slideshow1/3.png";
import slideshow1image4 from "images/slideshow1/4.png";
import slideshow2image1 from "images/slideshow2/1.png";
import slideshow2image2 from "images/slideshow2/2.png";
import slideshow2image3 from "images/slideshow2/3.png";
import slideshow2image4 from "images/slideshow2/4.png";

export const kilims = [
  { image: slideshow1image1, alt: "Kilim image 1" },
  { image: slideshow1image2, alt: "Kilim image 2" },
  { image: slideshow1image3, alt: "Kilim image 3" },
  { image: slideshow1image4, alt: "Kilim image 4" }
];

export const stories = [
  {
    image: slideshow2image1,
    alt: "Nina & Ivana",
    title: "Nina &amp; Ivana",
    text: `
      It was our first time that we tried the pattern generator. We were struggling a bit finding something meaningful to write in the program. We really enjoyed reading Scritto di notte (Written by night) by Ettore Sottsass, a designer that we admire, so we used those words for our first kilim sample.
    `
  },
  {
    image: slideshow2image2,
    alt: "Katja & Žiga",
    title: "Katja &amp; Žiga",
    text: `
      Žiga and I ordered the kilim as soon as we moved from Barcelona to Ljubljana. We were still
      full of impressions. We chose the words that popped into our heads at that moment. We saw
      Miro's works just before moving back to Ljubljana, and the impressions were still fresh. The
      first album we bought after moving in our new flat was The Division Bell, we were listening to
      the song Marooned and we were thinking about the words to put on the kilim. We had a
      laptop opened, we were planning our next trip. Malta seemed an attractive destination and
      the amazing photos of Blue Grotto appeared on the screen.
    `
  },
  {
    image: slideshow2image3,
    alt: "Sven",
    title: "Sven",
    text: `
      <p>
        The possibility to co-create my own kilim pattern made me very curious. The words that I chose have a special meaning to me personally.
      </p>
      <p>
        Present: Our life is a past, present and future time line. The sum of the past and the future is constant, the present is a short moment; “a snap of a moment between past and future”.
        But it also means giving, being in the present moment, listening, taking care of, presentingyourself and what you stand for.
      </p>
      <p>
        Nature: It is a living proof of earth's diversity. It gives me energy and motivates me to stand up for sustainability.
      </p>
      <p>
        Trust: Trust is the foundation for any relationship and it is a basis for well-being and love.
      </p>
      <p>
        Music: As an 8-year-old boy I started to sing in a choir, and I am still singing. Singing calms me down, it gives me energy every week, it is an island in everyday life.
      </p>
      <p>
        The result was really beautiful. I loved it from the first second. And what makes it even better is that I am the only one who has such a pattern.
      </p>
    `
  },
  {
    image: slideshow2image4,
    alt: "Alenka",
    title: "Alenka",
    text: `
      It was a great experience for me to have had the opportunity to co-create my own kilim. I
      started thinking about the words that are important to me and that I would like to become part
      of my everyday environment. I decided to use expressions that have to do with nature, such
      as: the sea, the mountains, the snow etc. I wanted to create a feeling in my home that would
      give me a sense of connection with nature and at the same time of transcending the
      cognitive.
    `
  }
];
