import minWidth from "./minWidth";
import maxWidth from "./maxWidth";

export { minWidth, maxWidth };
export * from "./devices";

export const scrollToTop = () => {
  setTimeout(() => {
    document.querySelector("html").scrollTop = 0;
    document.body.onscroll = document.documentElement.scrollTop = 0;
    window.scroll(0, 0);
  }, 15);
};

export const enableScroll = (scrollTop) => {
  document.body.style.position = "";
  document.body.style.width = "";
  document.body.style.overflowY = "";
  document.documentElement.scrollTop = scrollTop;
};

export const disableScroll = () => {
  let scrollTop = 0;

  if (document.body.clientHeight > window.innerHeight) {
    scrollTop = document.documentElement.scrollTop;
    document.body.style.position = "fixed";
    document.body.style.width = "100%";
    document.body.style.overflowY = "scroll";
  }

  return scrollTop;
};

export const isEmailValid = (email) => {
  const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return re.test(email);
};

export const sizeToPrice = {
  Medium: "420 &euro;",
  Large: "1188 &euro;",
  XLarge: "2640 &euro;",
  "Custom size": "Price on demand"
};
