import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Generator from "components/Generator";
import Admin from "components/Admin";
import PageNotFound from "components/PageNotFound";

const App = () => {
  return (
    <Wrapper>
      <Router>
        <Switch>
          <Route path="/" exact component={Generator} />
          <Route path="/admin" component={Admin} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 100vh;
`;

export default App;
