import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { API } from "aws-amplify";
import { orderBy } from "lodash";

import { colors, paddingSmall } from "variables";

import Loader from "components/Shared/Loader";

const Home = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const listDocuments = useCallback(async () => {
    setLoading(true);
    setError("");

    try {
      const response = await API.get("api", "/documents", {
        queryStringParameters: {}
      });
      setDocuments(orderBy(response, "created_at", "desc"));
    } catch (error) {
      setError(error.response.data);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    listDocuments();
  }, [listDocuments]);

  const handleUpdateDocument = async (id, data) => {
    setLoading(true);
    setError("");

    try {
      await API.patch("api", `/documents/${id}`, {
        body: data
      });
      listDocuments();
    } catch (error) {
      setError(error.response.data.message);
    }

    setLoading(false);
  };

  return (
    <Wrapper>
      <Container>
        <Loader isLoading={loading}>
          {() =>
            error ? (
              <div>{error}</div>
            ) : documents && documents.length > 0 ? (
              <Table>
                <thead>
                  <Row>
                    <HeaderCell>ID</HeaderCell>
                    <HeaderCell>Naročnik</HeaderCell>
                    <HeaderCell>E-mail</HeaderCell>
                    <HeaderCell small># 1</HeaderCell>
                    <HeaderCell small># 2</HeaderCell>
                    <HeaderCell small># 3</HeaderCell>
                    <HeaderCell small># 4</HeaderCell>
                    <HeaderCell small>Slika</HeaderCell>
                    <HeaderCell small>PDF</HeaderCell>
                    <HeaderCell>Besedilo</HeaderCell>
                    <HeaderCell medium>Velikost</HeaderCell>
                    <HeaderCell>Ustvarjeno</HeaderCell>
                    <HeaderCell>Naročeno</HeaderCell>
                    <HeaderCell>Plačano</HeaderCell>
                    <HeaderCell>Zaključeno</HeaderCell>
                  </Row>
                </thead>
                <tbody>
                  {documents.map((doc) => (
                    <Row key={doc.id}>
                      <BodyCell>{doc.id}</BodyCell>
                      <BodyCell>
                        {doc.customer_name}
                        {doc.customer_address
                          ? `, ${doc.customer_address}, ${
                              doc.customer_zip_code
                            } ${doc.customer_city}, ${
                              doc.customer_state
                                ? `${doc.customer_state}, ${doc.customer_country}`
                                : doc.customer_country
                            }`
                          : ""}
                      </BodyCell>
                      <BodyCell>
                        <Link
                          href={`mailto:${doc.customer_email}?subject=Kobeiagi Kilims: Order (${doc.customer_name})`}
                        >
                          {doc.customer_email}
                        </Link>
                      </BodyCell>
                      <BodyCell small>
                        <Link
                          href={doc.pattern_url_1}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Odpri
                        </Link>
                      </BodyCell>
                      <BodyCell small>
                        <Link
                          href={doc.pattern_url_2}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Odpri
                        </Link>
                      </BodyCell>
                      <BodyCell small>
                        <Link
                          href={doc.pattern_url_3}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Odpri
                        </Link>
                      </BodyCell>
                      <BodyCell small>
                        <Link
                          href={doc.pattern_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Odpri
                        </Link>
                      </BodyCell>
                      <BodyCell small>
                        <Link
                          href={doc.image_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Odpri
                        </Link>
                      </BodyCell>
                      <BodyCell small>
                        <Link
                          href={doc.pdf_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Odpri
                        </Link>
                      </BodyCell>
                      <BodyCell>{doc.text}</BodyCell>
                      <BodyCell medium>
                        {doc.size}
                        <br />
                        {doc.width} x {doc.height} cm
                      </BodyCell>
                      <BodyCell>
                        {new Date(doc.created_at).toDateString()}
                      </BodyCell>
                      <BodyCell>
                        <CheckBox
                          type="checkbox"
                          checked={Boolean(doc.ordered)}
                          onChange={(event) =>
                            handleUpdateDocument(doc.id, {
                              ordered: event.target.checked
                            })
                          }
                        />
                      </BodyCell>
                      <BodyCell>
                        <CheckBox
                          type="checkbox"
                          checked={Boolean(doc.payed)}
                          onChange={(event) =>
                            handleUpdateDocument(doc.id, {
                              payed: event.target.checked
                            })
                          }
                        />
                      </BodyCell>
                      <BodyCell>
                        <CheckBox
                          type="checkbox"
                          checked={Boolean(doc.completed)}
                          onChange={(event) =>
                            handleUpdateDocument(doc.id, {
                              completed: event.target.checked
                            })
                          }
                        />
                      </BodyCell>
                    </Row>
                  ))}
                </tbody>
              </Table>
            ) : (
              <EmptyList>Seznam dokumentov je prazen.</EmptyList>
            )
          }
        </Loader>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: calc(1800px + 2 * ${paddingSmall});
  margin: 0 auto;
  padding: 110px 0 50px;
  background-color: ${colors.background};
  color: ${colors.black};
  font-family: "Montserrat-Regular";
  font-size: 20px;
`;

const Container = styled.div`
  padding: 0 ${paddingSmall};
`;

const Table = styled.table`
  width: 100%;
`;

const Row = styled.tr``;

const HeaderCell = styled.th`
  ${({ small }) => small && "width: 60px;"}
  ${({ medium }) => medium && "width: 120px;"}
  padding: 5px 10px;
  border-bottom: 2px solid ${colors.black};
  font-family: sans-serif;
  font-size: 16px;
`;

const BodyCell = styled.td`
  ${({ small }) => small && "width: 60px;"}
  ${({ medium }) => medium && "width: 120px;"}
  padding: 5px 10px;
  border-bottom: 1px solid ${colors.black};
  font-family: sans-serif;
  font-size: 16px;
`;

const Link = styled.a`
  color: ${colors.text};
  font-family: sans-serif;

  &:hover {
    text-decoration: underline;
  }
`;

const CheckBox = styled.input`
  cursor: pointer;
`;

const EmptyList = styled.div`
  font-size: 16px;
`;

export default Home;
