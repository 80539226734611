import { useState, useEffect, useRef } from "react";
import styled, { css, keyframes } from "styled-components";

import { colors } from "variables";
import { minWidth, enableScroll, disableScroll } from "utils";

import Icon from "components/Shared/Icon";

import backgroundAboutImage from "images/background-about.svg";

const Illustrations = ({ isOpened, onClose }) => {
  const refScrollTop = useRef(0);
  useEffect(() => {
    if (isOpened) {
      refScrollTop.current = disableScroll();
    } else {
      enableScroll(refScrollTop.current);
    }
  }, [isOpened]);

  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    // disable the animation on first render
    const timeoutId = setTimeout(() => setAnimate(true), 500);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Wrapper isOpened={isOpened} animate={animate}>
      <Close name="close" alt="Close" title="Close" onClick={onClose} />
      <Content>
        <Paragraph>
          Pattern generator is a platform initiated by Kobeiagi Kilims, a
          Slovenian/Bosnian brand that designs and manufactures hand woven
          kilims and hand knotted rugs; and a young Slovene designer Nikolaj
          Salaj.
        </Paragraph>
        <Paragraph narrow>
          We have reimagined the kilim as a modern product without sacrificing
          its traditional method of manufacture. That is the reason we have
          created new patterns that would fit into the lives of our family and
          friends.
        </Paragraph>
        <Link
          href="https://www.kobeiagikilims.com"
          target="_blank"
          rel="noreferrer"
        >
          kobeiagikilims.com
        </Link>
        <Link
          href="https://www.instagram.com/kobeiagi_kilims/"
          target="_blank"
          rel="noreferrer"
        >
          Instagram
        </Link>
      </Content>
    </Wrapper>
  );
};

const translateIn = keyframes`
  0% {
    transform: translateX(-50%);
    visibility: hidden;
  }
 100% {
    transform: translateX(0);
    visibility: visible;
  }
`;

const translateOut = keyframes`
  0% {
    transform: translateX(0);
    visibility: visible;
  }
 100% {
    transform: translateX(-50%);
    visibility: hidden;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  padding: 20px 20px 100px;
  transform: translateX(-100%);
  z-index: 20;
  background: url(${backgroundAboutImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -235px;

  ${minWidth.mobileM`
    background-position: -320px;
  `}

  @media (orientation: landscape) {
    background-position: top;
  }

  ${minWidth.tablet`
    padding: 20px 60px 100px;
  `}

  ${minWidth.hamburgerLarge`
    padding: 50px 100px 100px;
  `}

  ${minWidth.largest`
    background-size: auto;
    background-position: unset;

    @media (orientation: landscape) {
      background-position: unset;
    }
  `}

  ${({ isOpened, animate }) =>
    isOpened
      ? css`
          animation: ${translateIn} ${animate ? 0.5 : 0}s ease-out 0s forwards;
        `
      : css`
          animation: ${translateOut} ${animate ? 0.5 : 0}s ease-in 0s forwards;
        `}
`;

const Close = styled(Icon)`
  width: 40px;
  height: 40px;
  cursor: pointer;

  ${minWidth.mobile`
    width: 55px;
    height: 55px;
  `}

  ${minWidth.hamburgerLarge`
    width: 80px;
    height: 80px;
  `}

  .close {
    transition: stroke 0.15s linear;
  }

  &:hover {
    .close {
      stroke: ${colors.white};
    }
  }
`;

const Content = styled.div`
  height: 100%;
  padding-bottom: 20px;
  overflow-y: auto;
`;

const Paragraph = styled.p`
  width: ${({ narrow }) => (narrow ? "60%" : "89%")};
  margin: 10px 0;
  color: ${colors.black};
  font-size: 18px;

  ${minWidth.mobileM`
    width: ${({ narrow }) => (narrow ? "60%" : "83%")};
    margin: 20px 0;
  `}

  ${minWidth.mobile`
    width: 74%;
    margin: 30px 0;
    font-size: 20px;
  `}

  ${minWidth.tablet`
    width: 60%;
    font-size: 24px;
  `}

  ${minWidth.largest`
    width: 40%;
  `}
`;

const Link = styled.a`
  width: fit-content;
  display: block;
  color: ${colors.black};
  font-family: "CrimsonText-Italic";
  font-size: 20px;
  text-decoration: underline;
  text-underline-offset: 8px;
  transition: color 0.15s linear;

  ${minWidth.tablet`
    font-size: 24px;
  `}

  &:hover {
    color: ${colors.white};
    text-decoration: underline;
  }
`;

export default Illustrations;
