import styled from "styled-components";

import { colors } from "variables";

const Input = styled.input`
  width: 100%;
  border: none;
  border-bottom: 2px solid ${colors.salmon};
  font-family: "Montserrat-Medium";
  font-size: 24px;
  background-color: transparent;
  color: ${colors.salmon};
  outline: none;

  &::placeholder {
    color: ${colors.salmon};
    opacity: 1;
  }
`;

export default Input;
