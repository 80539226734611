import React from "react";

import { ReactComponent as Arrow } from "images/arrow.svg";
import { ReactComponent as Close } from "images/close.svg";
import { ReactComponent as ButtonCreate } from "images/button-create.svg";
import { ReactComponent as ButtonDownload } from "images/button-download.svg";
import { ReactComponent as ButtonGenerate } from "images/button-generate.svg";
import { ReactComponent as ButtonOrder } from "images/button-order.svg";
import { ReactComponent as KilimMedium } from "images/kilim-sizes/medium.svg";
import { ReactComponent as KilimLarge } from "images/kilim-sizes/large.svg";
import { ReactComponent as KilimXLarge } from "images/kilim-sizes/xlarge.svg";
import { ReactComponent as KilimCustom } from "images/kilim-sizes/custom.svg";
import { ReactComponent as LoaderGenerate } from "images/loader-generate.svg";
import { ReactComponent as Logo } from "images/logo.svg";
import { ReactComponent as Pattern } from "images/pattern.svg";
import { ReactComponent as Slogan1 } from "images/slogan-1.svg";
import { ReactComponent as Slogan2 } from "images/slogan-2.svg";

const icons = {
  arrow: Arrow,
  close: Close,
  "button-create": ButtonCreate,
  "button-download": ButtonDownload,
  "button-generate": ButtonGenerate,
  "button-order": ButtonOrder,
  "kilim-medium": KilimMedium,
  "kilim-large": KilimLarge,
  "kilim-xlarge": KilimXLarge,
  "kilim-custom": KilimCustom,
  "loader-generate": LoaderGenerate,
  logo: Logo,
  pattern: Pattern,
  "slogan-1": Slogan1,
  "slogan-2": Slogan2
};

const Icon = ({ name, ...props }) => {
  const Component = name in icons ? icons[name] : null;
  return Component ? <Component {...props} /> : null;
};

export default Icon;
