import { css } from "styled-components";

import { breakpoints } from "variables";

/**
 * A function that applies a media query inside a styled component, based on the
 * max-width selector and breakpoints defined in app theme. Used for desktop-first
 * approach to design.
 *
 * https://www.styled-components.com/docs/advanced#media-templates
 */
const maxWidth = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export default maxWidth;
