import { useState, useEffect } from "react";
import { Switch, useHistory, withRouter } from "react-router-dom";
import styled from "styled-components";
import { Auth } from "aws-amplify";

import Login from "./Login";
import Home from "./Home";
import AuthenticatedRoute from "./AuthenticatedRoute";
import UnauthenticatedRoute from "./UnauthenticatedRoute";

import { colors, paddingSmall, contentWidthWide } from "variables";

import "bootstrap/dist/css/bootstrap.min.css";

import logoImage from "images/logo.svg";

const isLocalEnv = process.env.REACT_APP_STAGE === "local";

const Admin = () => {
  const history = useHistory();

  const [user, setUser] = useState(null);
  const isAuthenticated = Boolean(user);
  const [isAuthenticating, setAuthenticating] = useState(true);

  useEffect(() => {
    const getCurrentAuthenticatedUser = async () => {
      try {
        const { attributes } = isLocalEnv
          ? { attributes: { email: "local@user.com" } }
          : await Auth.currentAuthenticatedUser();
        setUser(attributes);
      } catch (error) {
        if (error !== "not authenticated") {
          console.log(error);
        }
      }
      setAuthenticating(false);
    };

    getCurrentAuthenticatedUser();
  }, [history]);

  const handleLogout = async () => {
    if (!isLocalEnv) {
      await Auth.signOut();
    }
    setUser(null);
  };

  return (
    <Wrapper>
      <Header>
        <Navigation>
          <LogoWrapper>
            <a href="/admin">
              <img src={logoImage} alt="Logo" title="Logo" />
            </a>
          </LogoWrapper>
          <Title>Pattern Generator</Title>
          <User>
            {isAuthenticated && (
              <>
                <Email>{user.email}</Email>
                <Logout onClick={handleLogout}>Odjavi se</Logout>
              </>
            )}
          </User>
        </Navigation>
      </Header>
      <Switch>
        <UnauthenticatedRoute
          key="login"
          path="/admin"
          exact
          component={Login}
          props={{
            setUser,
            isAuthenticated,
            isAuthenticating,
            setAuthenticating
          }}
        />
        <AuthenticatedRoute
          key="home"
          path="/admin/home"
          exact
          component={Home}
          props={{ user, setUser, isAuthenticated }}
        />
      </Switch>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: ${colors.background};
`;

const Header = styled.div`
  position: fixed;
  width: 100%;
  height: 100px;
  padding: 10px 20px;
  background-color: #f3f2f4;
`;

const Navigation = styled.div`
  position: relative;
  max-width: ${contentWidthWide};
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoWrapper = styled.div`
  width: 120px;
`;

const Title = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-family: "Montserrat-Regular";
  font-size: 30px;
  text-transform: uppercase;
`;

const User = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Email = styled.div`
  color: ${colors.black};
  font-family: "Montserrat-Regular";
`;

const Logout = styled.div`
  margin-left: ${paddingSmall};
  font-family: "Montserrat-Regular";
  cursor: pointer;
  transition: color 0.15s linear;

  &:hover {
    text-decoration: underline;
  }
`;

export default withRouter(Admin);
