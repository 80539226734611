import { Route, Redirect } from "react-router-dom";

const UnauthenticatedRoute = ({
  component: Component,
  props: childProps,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        childProps.isAuthenticated ? (
          <Redirect
            to={
              getQueryParam(props.location.search, "redirect") || "/admin/home"
            }
          />
        ) : (
          <Component {...props} {...childProps} />
        )
      }
    />
  );
};

const getQueryParam = (queryString, param) =>
  new URLSearchParams(queryString).get(param);

export default UnauthenticatedRoute;
