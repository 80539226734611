import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import Button from "react-bootstrap/Button";

import { colors, gapSmallest, gapExtraSmall } from "variables";

export const StyledButton = styled.button`
  color: ${colors.text};
  border: 1px solid ${colors.text};
  padding: ${gapSmallest} ${gapExtraSmall};
  background-color: ${colors.greenLight} !important;
  cursor: pointer;
  outline: none;
  border-radius: 8px;
  font-weight: 400;

  &:hover {
    color: ${colors.green};
    border: 1px solid ${colors.green};
  }
`;

export const ButtonOutlined = styled.button`
  color: ${colors.text};
  border: 1px solid ${colors.text};
  padding: ${gapSmallest} ${gapExtraSmall};
  cursor: pointer;
  outline: none;
  border-radius: 8px;
  font-weight: 400;

  &:hover {
    color: ${colors.green};
    border: 1px solid ${colors.green};
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledFeatherIcon = styled(FeatherIcon)`
  height: 16px;
  margin-right: 8px;
`;

const Text = styled.div`
  margin-right: 4px;
`;

export const ButtonIcon = ({ className, children, icon, ...props }) => (
  <Button variant="outline-primary" className={className} {...props}>
    <Content>
      <StyledFeatherIcon icon={icon} />
      <Text>{children}</Text>
    </Content>
  </Button>
);

export default Button;
